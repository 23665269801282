import React, { useRef } from "react";
import classNames from "classnames";
import { useHistory, useLocation } from "react-router-dom";
import DefaultSelect from "components/DefaultSelect";
import { TooltipContainer } from "@outplayed/tooltips";
import ReactSVG from "react-svg";
import { getApiRole } from "lib/general-helper";
import { TIER_LIST_ROLE_OPTIONS } from "query_parameters/filter_options";
import { useTranslation } from "react-i18next";

const Role = (props) => {
  const tooltipRef = useRef();
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();

  const {
    className,
    page,
    realPage,
    deactivate,
    disable,
    role,
    options,
    filters,
    isDefault,
    onFilterChange,
    disableTooltip,
    disableByQueueType,
    isMobile,
  } = props;

  if (!deactivate && disable && disable.includes("role")) {
    return null;
  }

  if (!deactivate && disable && !disable.includes("queueType")) {
    const queueType = filters.queueType;
    if (queueType === "normal_aram" || queueType === "nexus_blitz") {
      return null;
    }
  }

  let isDisabled = disableByQueueType && (filters.queueType === "normal_aram" || filters.queueType === "nexus_blitz");

  const formatOptionLabel = ({ value, label, img, imgAlt }) => {
    return (
      <div className="role-value">
        {img}
        <div style={{ marginLeft: "12px" }}>{t(label)}</div>
      </div>
    );
  };

  const onChange = (selected) => {
    history.push({
      pathname: selected.path,
      search: location.search,
    });
  };

  return (
    <TooltipContainer
      ref={tooltipRef}
      tooltipInfo={t("Change Role")}
      customClassName={className}
      disableTooltip={isMobile || disableTooltip}
    >
      <div>
        <DefaultSelect
          title={t("Change Role")}
          group={"role"}
          isMobile={isMobile}
          className={`filter-select ${isDefault}`}
          options={TIER_LIST_ROLE_OPTIONS}
          onChange={onChange}
          value={realPage}
          formatOptionLabel={formatOptionLabel}
          disabled={isDisabled}
          width={110}
          isSearchable={false}
          onMenuClose={() => tooltipRef.current.forceClose()}
        />
      </div>
    </TooltipContainer>
  );
};

export default Role;
