import React, { useRef } from "react";

import classNames from "classnames";
import DefaultSelect from "components/DefaultSelect";
import { TooltipContainer } from "@outplayed/tooltips";
import { useTranslation } from "react-i18next";

import { getRevApiRole } from "lib/general-helper";
import { normalizeRole, getRoleImg, getRoleSVG } from "lib/role_helper.js";
import { DUO_TIER_LIST_ROLE_OPTIONS } from "query_parameters/filter_options";

const DuoRole = (props) => {
  const tooltipRef = useRef();
  const { t } = useTranslation();
  const { className, page, filters, options, defaultParams, onFilterChange, isMobile, disableTooltip } = props;

  if (page !== "duo-tier-list") {
    return null;
  }

  const defaultSelected = filters.duo === defaultParams.duo ? "" : "filter_not-default";

  const formatOptionLabel = ({ value, label }) => {
    const pair = value.split("_");
    const role1 = normalizeRole(pair[0]);
    const role2 = normalizeRole(pair[1]);

    return (
      <div className="duo-role-option">
        {getRoleSVG(role1)}
        <span className="forward-slash">/</span>
        {getRoleSVG(role2)}
      </div>
    );
  };

  return (
    <TooltipContainer
      ref={tooltipRef}
      tooltipInfo={t("Change Duo Roles")}
      customClassName={className}
      disableTooltip={isMobile || disableTooltip}
    >
      <div>
        <DefaultSelect
          className={`filter-select ${defaultSelected}`}
          options={options}
          onChange={(selected) => {
            onFilterChange("duo", selected);
          }}
          value={filters.duo}
          formatOptionLabel={formatOptionLabel}
          isSearchable={false}
          width={110}
          onMenuClose={() => tooltipRef.current.forceClose()}
        />
      </div>
    </TooltipContainer>
  );
};

export default DuoRole;
