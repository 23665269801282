import React, { Component } from "react";

import OppChampionSearch from "components/Champions/OppChampionSearch";

export default class OppMatchup extends Component {
  render() {
    const { isMobile, enable, disable, filters, location, history, rankingStats } = this.props;

    if (disable && disable.includes("role")) {
      return null;
    }

    if (disable && !disable.includes("queueType")) {
      const queueType = filters.queueType;
      if (queueType === "normal_aram" || queueType === "nexus_blitz") {
        return null;
      }
    }

    if (!enable || !enable.includes("oppChampion")) {
      return null;
    }

    if (filters.queueType !== "ranked_solo_5x5" && filters.queueType !== "ranked_flex_sr") {
      return null;
    }

    return (
      <OppChampionSearch
        isMobile={isMobile}
        location={location}
        history={history}
        filters={filters}
        rankingStats={rankingStats}
      />
    );
  }
}
