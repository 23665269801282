import React, { Component, Fragment } from "react";
import Modal from "components/Modal";
import FilterModal from "components/FilterManager/FilterModal";
import ReactSVG from "react-svg";
import Switch from "react-switch";

class VictoryOnly extends Component {
  constructor(props) {
    super(props);

    this.state = {
      toggle: props.filters.isVictoryOnly === true ? true : false,
    };
  }

  onChangeHandler = () => {
    this.setState({ toggle: !this.state.toggle });
  };

  onCancel = (e) => {};

  onApply = () => {
    const { toggle } = this.state;
    const { onFilterChange, filters } = this.props;

    if (filters.isVictoryOnly !== `${toggle}`) {
      onFilterChange("isVictoryOnly", {
        value: `${toggle}`,
      });
    }
  };

  render() {
    const { toggle } = this.state;
    const { isMobile, enable, filters, onFilterChange, filterId } = this.props;

    if (!enable || !enable.includes("isVictoryOnly")) {
      return null;
    }

    const active = filters["isVictoryOnly"] === "true" ? true : false;

    const button = <div className={`filter-select filter-select_victory-only ${active ? "active" : ""}`}>Victory Only</div>;

    if (isMobile) {
      return (
        <FilterModal
          button={button}
          title={"Toggle Victory Only"}
          onChange={this.onChangeHandler}
          onApply={this.onApply}
          onCancel={this.onCancel}
        >
          <div className="filter-modal_content__victory-only">
            <div className="switch-row">
              <div>{"Don't show"}</div>
              <Switch
                className="switch-container"
                width={72}
                height={36}
                handleDiameter={30}
                onColor={"#222238"}
                offColor={"#222238"}
                onHandleColor={"#08A6FF"}
                offHandleColor={"#FF4E50"}
                uncheckedIcon={false}
                checkedIcon={false}
                onChange={this.onChangeHandler}
                checked={toggle}
              />
              <div>{"Show"}</div>
            </div>
            <div className="filter-description">Only show winning pro games</div>
          </div>
        </FilterModal>
      );
    }

    return (
      <Modal
        dataTip={"Toggle Victory Only"}
        modalClassNames={"select_modal__modal-window__filter"}
        button={button}
        onApply={this.onApply}
        onCancel={this.onCancel}
      >
        <div className="modal_toggle">
          <div className="filter-description">
            <div className="title">{"Victory Only"}</div>
            <div className="sub-text">{"Only show winning pro games"}</div>
          </div>
          <div className="switch-container">
            <Switch
              width={72}
              height={36}
              handleDiameter={30}
              onColor={"#222238"}
              offColor={"#222238"}
              onHandleColor={"#08A6FF"}
              offHandleColor={"#FF4E50"}
              uncheckedIcon={false}
              checkedIcon={false}
              onChange={this.onChangeHandler}
              checked={toggle}
            />
          </div>
        </div>
      </Modal>
    );
  }
}

export default VictoryOnly;
