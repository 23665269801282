import React, { useRef } from "react";
import DefaultSelect from "components/DefaultSelect";
import { TooltipContainer } from "@outplayed/tooltips";
import ReactSVG from "react-svg";
import { ReactComponent as Tools } from "../../../svg/tools.svg";
import { getBlockImg, getImg } from "lib/region_helper";
import { useTranslation } from "react-i18next";

const MoreLessButton = (props) => {
  const tooltipRef = useRef();
  const { t } = useTranslation();
  const { label } = props;

  const title = label ? "Less..." : "More...";

  return (
    <div className="more-button">
      <Tools />
      <span>{title}</span>
    </div>
  );
};

export default MoreLessButton;
