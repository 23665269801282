import React, { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import classnames from "classnames";
import { ReactComponent as SearchIcon } from "svg/search-icon-2.svg";
import { ReactComponent as X } from "svg/x.svg";
import { ReactComponent as XButton } from "svg/x-button.svg";
import { useHistory } from "react-router-dom";
import { getRiotAssetsContext } from "@outplayed/riot-assets";
import { buildQueryParams, parseQueryString } from "lib/general-helper.js";

const VSChampionFilter = () => {
  const { getChampionName, useChampionMini, getNormalizedChampionName, getChampionIdByName, getChampionImgFromSprite } =
    getRiotAssetsContext();

  const { data: championMini } = useChampionMini({ ssr: true });
  const [searchValue, setSearchValue] = useState("");
  const [currentSearchOption, setCurrentSearchOption] = useState();
  const [inputActive, setInputActive] = useState(false);
  const [highlightedItem, setHighlightedItem] = useState(0);
  const location = useLocation();

  const history = useHistory();
  const multiFilterRef = useRef();
  const multiFilterLabelRef = useRef();
  const inputRef = useRef();

  useEffect(() => {
    if (inputActive) {
      inputRef.current.focus();
    } else {
      setSearchValue("");
      setCurrentSearchOption(undefined);
    }
  }, [inputActive]);

  const filterOptions = (options) =>
    options.filter(({ name }) => {
      const normalizedChampionName = name.toLowerCase().replace(/[^A-Za-z0-9]/g, "");
      const normalizedInput = searchValue.toLowerCase().replace(/[^A-Za-z0-9]/g, "");
      return normalizedChampionName.startsWith(normalizedInput);
    });

  const onApply = (option) => {
    let simplifiedChampionName = "allChampions";
    if (option && option.value !== 0) {
      simplifiedChampionName = getNormalizedChampionName(option.value);
    }

    let params = parseQueryString(location.search.substring(1));

    if (
      (!params.opp && simplifiedChampionName === "allChampions") ||
      (params.opp && params.opp.toLowerCase() === simplifiedChampionName)
    ) {
      return;
    }

    if (simplifiedChampionName !== "allChampions") {
      params.opp = simplifiedChampionName;
    } else {
      delete params.opp;
    }

    if (window && window.dataLayer) {
      window.dataLayer.push({
        event: "filter-select",
        category: "filter",
        filterType: "matchup",
        filterValue: simplifiedChampionName,
        page: "build",
      });
    }

    //Remove the multibuild from the URL
    const multis = ["/ap/", "/tank/", "/ad/", "/crit/", "/lethality/", "/onhit/", "/red/", "/blue/"];
    let url = history.location.pathname;
    for (const multi in multis) {
      if (url.includes(multis[multi])) {
        url = url.replace(multis[multi], "/");
      }
    }

    history.replace({
      pathname: url,
      search: `?${buildQueryParams(params)}`,
    });

    setCurrentSearchOption(simplifiedChampionName);
  };

  const onRemove = () => {
    setCurrentSearchOption(undefined);
    history.replace({
      pathname: history.location.pathname,
    });
  };

  //Renders the dropdown
  const championOptions = filterOptions(
    ((championMini && Object.values(championMini)) || [])
      .sort((a, b) => a.name.localeCompare(b.name))
      .map(({ key, name }) => ({
        value: key,
        name,
        onClick: (option) => onApply(option),
        render: (
          <>
            <div className="champion-icon" style={{ overflow: "hidden", marginRight: "8px" }}>
              {getChampionImgFromSprite(key, { size: 20 })}
            </div>
            <div>{getChampionName(key)}</div>
          </>
        ),
      })),
  );

  //allOptions are a list of the champions shown in the dropdown
  const allOptions = championOptions;

  const onKeyDown = (e) => {
    if (e.key === "Backspace" && searchValue.length === 0) {
      setCurrentSearchOption(undefined);
    }

    if (e.key === "Enter") {
      //The onclick gets fired from championOptions onClick:
      allOptions[highlightedItem].onClick(allOptions[highlightedItem]);
    }

    if (e.key === "ArrowUp") {
      e.preventDefault();
      setHighlightedItem(Math.abs(allOptions.length + highlightedItem - 1) % allOptions.length);
    }

    if (e.key === "ArrowDown") {
      e.preventDefault();
      setHighlightedItem(Math.abs(allOptions.length + highlightedItem + 1) % allOptions.length);
    }
  };

  useEffect(() => {
    if (window && inputRef.current && inputActive) {
      const outsideClick = (e) => {
        if (
          multiFilterRef.current &&
          e.target !== null &&
          !multiFilterRef.current.contains(e.target) &&
          document.contains(e.target)
        ) {
          setInputActive(false);
        }
      };
      window.addEventListener("click", outsideClick);

      return () => window.removeEventListener("click", outsideClick);
    }
  }, [inputActive]);

  const renderOptions = (options, highlightOffset) =>
    options.map((option, index) => (
      <div
        key={option.value}
        className={classnames("multi-filter-menu-item", highlightOffset + index === highlightedItem && "highlighted")}
        onMouseEnter={() => setHighlightedItem(highlightOffset + index)}
        onClick={() => option.onClick(option)}
      >
        {option.render}
      </div>
    ));

  const renderTags = () => {
    const params = parseQueryString(location.search.substring(1));
    const champId = getChampionIdByName(params.opp);

    return (
      <div className="multi-filter-tag" onClick={() => onRemove()}>
        <span>vs.</span>
        <div className="champion-icon">{getChampionImgFromSprite(champId, { size: 16 })}</div>
        <div className="champion-name">{getChampionName(champId)}</div>
        <XButton className="x-icon" />
      </div>
    );
  };

  const params = parseQueryString(location.search.substring(1));
  const championSelected = !(Object.keys(params).length === 0) && "opp" in params ? "champ-selected" : "no-champ";
  const searching = inputActive ? "searching" : "";

  return (
    <div className="multi-filter-champion" ref={multiFilterRef}>
      <div
        className={`multi-filter-label ${championSelected} ${searching}`}
        onClick={() => setInputActive(true)}
        ref={multiFilterLabelRef}
        style={{ width: "170px", height: "36px" }}
      >
        {!(Object.keys(params).length === 0) && "opp" in params ? (
          <div className="multi-filter-label-tags">{renderTags()}</div>
        ) : (
          <>
            <input
              placeholder={currentSearchOption === undefined ? "vs. Champion..." : currentSearchOption.value}
              value={searchValue}
              onChange={(e) => {
                setSearchValue(e.target.value);
                setHighlightedItem(0);
              }}
              onKeyDown={onKeyDown}
              ref={inputRef}
            />
            <SearchIcon className="search-icon" />
          </>
        )}
      </div>
      {inputActive && (
        <div className="multi-filter-menu-summoner-page">
          {(currentSearchOption === undefined || currentSearchOption.value === "championId") && (
            <>
              {championOptions.length > 0 && <div className={"multi-filter-menu-title"}>CHAMPIONS</div>}
              {renderOptions(championOptions, currentSearchOption && currentSearchOption.value === "championId" ? 0 : 0)}
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default VSChampionFilter;
