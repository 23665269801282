import React, { useMemo } from "react";
import classNames from "classnames";
import { useLocation, Link } from "react-router-dom";
import { useQueryString } from "hooks/general-hooks";
import { useTranslation } from "react-i18next";
import { TooltipContainer } from "@outplayed/tooltips";
import { getApiRole, buildQueryParams } from "lib/general-helper";
import { ROLE_OPTIONS, ALL_ROLE_OPTIONS, TEST_ROLE_OPTIONS, TEST_ALL_ROLE_OPTIONS } from "query_parameters/filter_options";
import { match, compile } from "path-to-regexp";
import { useGlobal } from "reactn";

const ChampionOverviewRoleSplit = (props) => {
  const { t } = useTranslation();
  const location = useLocation();
  const queryParams = useQueryString();
  const [language] = useGlobal("language");
  const { className, deactivate, disable, filters, disableTooltip } = props;

  const { champion, role } = useMemo(() => {
    const obj = ["/lol/:language?/champions/:champion/build/:role?"]
      .map((path) => {
        const foundMatch = match(path)(location.pathname);
        return foundMatch?.params;
      })
      .find((el) => el !== undefined);

    return obj || {};
  }, [location.pathname]);

  const displayRoleFilters = () => {
    let selectedRole;
    if (props.role) {
      selectedRole = getApiRole(props.role);
    }
    if (filters.role) {
      selectedRole = getApiRole(filters.role);
    }

    if (queryParams.role) {
      delete queryParams.role;
    }

    const roleOptions = props.allRoles ? TEST_ALL_ROLE_OPTIONS : TEST_ROLE_OPTIONS;
    const toPath = compile("/lol/:language?/champions/:champion/build/:role?", { validate: false });

    return roleOptions.map((option) => {
      const isSelectedRole = selectedRole === getApiRole(option.value);
      const selectorClassNames = classNames({
        "role-filter": true,
        active: !deactivate && isSelectedRole,
      });

      return (
        <TooltipContainer key={option.value} tooltipInfo={t(option.label)} disableTooltip={disableTooltip}>
          <Link
            className={selectorClassNames}
            to={{
              pathname:
                language !== "en_US"
                  ? toPath({ champion, language: language.toLowerCase(), role: option.value })
                  : toPath({ champion, role: option.value }),
              search: buildQueryParams(queryParams),
            }}
          >
            {isSelectedRole && option.imgActive ? option.imgActive : option.img}
          </Link>
        </TooltipContainer>
      );
    });
  };

  if (!deactivate && disable && disable.includes("role")) {
    return null;
  }

  if (!deactivate && disable && !disable.includes("queueType")) {
    const queueType = filters.queueType;
    if (queueType === "normal_aram" || queueType === "nexus_blitz") {
      return null;
    }
  }

  return (
    <div className={classNames("role-filter-container", { deactivated: deactivate }, className)}>{displayRoleFilters()}</div>
  );
};

export default ChampionOverviewRoleSplit;
