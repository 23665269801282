import React, { useRef } from "react";
import { TooltipContainer } from "@outplayed/tooltips";
import DefaultSelect from "components/DefaultSelect";
import { useTranslation } from "react-i18next";

const Patch = (props) => {
  const tooltipRef = useRef();
  const { t } = useTranslation();
  const { className, isMobile, disable, filters, isDefault, onFilterChange, disableTooltip, page, pageData, options } = props;

  if (disable && disable.includes("patch")) {
    return null;
  }

  const formatOptionLabel = ({ value, label }) => {
    if (value === "all") {
      return (
        <div className="Select-value">
          <span className="Select-value-label">{"All"}</span>
        </div>
      );
    } else {
      return (
        <div className="Select-value">
          <span className="Select-value-label">{label}</span>
        </div>
      );
    }
  };

  return (
    <TooltipContainer
      ref={tooltipRef}
      tooltipInfo={t("Change Patch")}
      customClassName={className}
      disableTooltip={isMobile || disableTooltip}
    >
      <div>
        <DefaultSelect
          title={t("Change Patch")}
          group={"patch"}
          isMobile={isMobile}
          className={`filter-select patch ${isDefault}`}
          options={options}
          isSearchable={false}
          onChange={(selected) => onFilterChange("patch", selected)}
          value={filters.patch}
          formatOptionLabel={formatOptionLabel}
          width={80}
          onMenuClose={() => tooltipRef.current.forceClose()}
        />
      </div>
    </TooltipContainer>
  );
};

export default Patch;
