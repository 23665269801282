import React, { Component } from "react";

import classNames from "classnames";
import { TooltipContainer } from "@outplayed/tooltips";

import { getApiRole } from "lib/general-helper";
import { NEXUS_BLITZ_ROLE_OPTIONS, ALL_NEXUS_BLITZ_ROLE_OPTIONS } from "query_parameters/filter_options";

import { useTranslation } from "react-i18next";
import { useLightMode } from "hooks/general-hooks";
import { getRoleSVG } from "lib/role_helper";

const NexusBlitzRoleSplit = (props) => {
  const { t } = useTranslation();
  const { lightModeOn } = useLightMode();
  const { className, deactivate, disable, filters, disableTooltip } = props;

  const displayRoleFilters = () => {
    let selectedRole;
    if (props.role) {
      selectedRole = getApiRole(props.role);
    }
    if (filters.role) {
      selectedRole = getApiRole(filters.role);
    }

    const roleOptions = props.allRoles ? ALL_NEXUS_BLITZ_ROLE_OPTIONS : NEXUS_BLITZ_ROLE_OPTIONS;

    return roleOptions.map((role) => {
      const isSelectedRole = selectedRole === getApiRole(role.value);

      const selectorClassNames = classNames({
        "role-filter": true,
        active: !deactivate && isSelectedRole,
      });

      return (
        <TooltipContainer key={role.value} tooltipInfo={t(role.label)} disableTooltip={disableTooltip}>
          <div className={selectorClassNames} onClick={() => props.onFilterChange("role", role)}>
            {getRoleSVG(role.value, { lightModeOn, active: isSelectedRole })}
          </div>
        </TooltipContainer>
      );
    });
  };

  if (!deactivate && disable && disable.includes("role")) {
    return null;
  }

  return <div className={`role-filter-container ${deactivate ? "deactivated" : ""} ${className}`}>{displayRoleFilters()}</div>;
};

export default NexusBlitzRoleSplit;
