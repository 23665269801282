import { window } from "global";
import React, { useRef, useState, useEffect } from "react";
import { Item, Rune } from "@outplayed/tooltips";
import classNames from "classnames";
import { compile } from "path-to-regexp";
import { Link, useParams, useLocation, useRouteMatch } from "react-router-dom";
import { getRiotAssetsContext } from "@outplayed/riot-assets";
import { useTabData } from "lib/hooks/championProfileHooks";
import { alphanumeric } from "lib/general-helper";

const MythicItem = (props) => {
  const { itemId } = props;
  const { useRiotItems, isMythicItem } = getRiotAssetsContext();
  const { data } = useRiotItems({ itemId });

  return props.children(isMythicItem((data || {})[itemId]));
};

const MultiBuildsFilter = (props) => {
  const urlParams = useParams();
  const location = useLocation();
  const multiRef = useRef();
  const { getNormalizedChampionName, getChampionIdByName } = getRiotAssetsContext();
  const championId = getChampionIdByName(alphanumeric(urlParams.champion.split("-")[0]));
  const championName = getNormalizedChampionName(championId).toLowerCase();
  const { role } = props;
  const data = useTabData(role);
  const tabData = data[0];
  const version = data[1];
  const multi = urlParams.multi ? capitalizeMulti(urlParams.multi) : "Rec.";
  const { path } = useRouteMatch();

  const [showAll, setShowAll] = useState(false);

  const handleShowAll = () => {
    setShowAll(!showAll);
    const filterValue = showAll === false ? "Filter dropdown open" : "Filter dropdown close";
    if (window.dataLayer) {
      window.dataLayer.push({
        event: "multibuild-filter-select",
        category: "filter",
        filterType: "multibuild",
        filterValue: filterValue,
      });
    }
  };

  useEffect(() => {
    if (window) {
      const handleOutsideClick = (e) => {
        const path = e.path || (e.composedPath && e.composedPath());
        if (multiRef.current && e.target !== null && !multiRef.current.contains(e.target) && !path.includes(multiRef.current)) {
          setShowAll(false);
        }
      };

      window.addEventListener("click", handleOutsideClick);
      return () => window.removeEventListener("click", handleOutsideClick);
    }
  }, []);

  const multiURL = (multi) => {
    const toPath = compile(path);
    return toPath({ ...urlParams, multi }) + location.search;
  };

  function capitalizeMulti(multi) {
    switch (multi) {
      case "ap":
      case "ad":
        return multi.toUpperCase();
      case "tank":
      case "crit":
      case "lethality":
        return multi.substring(0, 1).toUpperCase() + multi.substring(1);
      case "onhit":
        return "On Hit";
      case "red":
        return "Red";
      case "blue":
        return "Blue";
      default:
        return "Rec.";
    }
  }

  const multiNames = Object.getOwnPropertyNames(tabData);

  let multiData = championId !== 141 ? tabData["default"] : tabData;

  for (const buildName in multiNames) {
    if (multiNames[buildName] === urlParams.multi) {
      multiData = tabData[multiNames[buildName]];
    }
  }

  const eventFire = (multi, index) => {
    const build = multi === "default" ? "Recommended" : capitalizeMulti(multi);
    if (window.dataLayer) {
      window.dataLayer.push({
        event: "multibuild-filter-select",
        category: "filter",
        filterType: "multibuild",
        filterValue: build + "-" + championName + "-filter-" + (index + 1),
      });
    }
  };

  return championId === 141 ? (
    tabData[100141][0] !== undefined && (
      <div className={classNames("multi-builds-filter-button", { active: showAll })} ref={multiRef} onClick={handleShowAll}>
        <div className="multi-builds-content">
          <span>{multi ? multi : "Blue"}</span>
          {multi === "Red" ? (
            <div className="image-holder">
              {multiData[100141][1] != 0 && (
                <div className="rune-image-container">
                  <Rune patch={version} runeId={multiData[100141] && multiData[100141][0]} ssr />
                </div>
              )}
              {multiData[100141] && multiData[100141][1] != 0 && (
                <MythicItem itemId={multiData[100141] && multiData[100141][1]}>
                  {(isMythic) => {
                    return (
                      <div className={classNames("item-image-container", { "is-mythic": isMythic })}>
                        <Item itemId={multiData[100141] && multiData[100141][1]} ssr />
                      </div>
                    );
                  }}
                </MythicItem>
              )}
            </div>
          ) : (
            <div className="image-holder">
              {multiData[200141][1] != 0 && (
                <div className="rune-image-container">
                  <Rune patch={version} runeId={multiData[200141] && multiData[200141][0]} ssr />
                </div>
              )}
              {multiData[200141] && multiData[200141][1] != 0 && (
                <MythicItem itemId={multiData[200141] && multiData[200141][1]}>
                  {(isMythic) => {
                    return (
                      <div className={classNames("item-image-container", { "is-mythic": isMythic })}>
                        <Item itemId={multiData[200141] && multiData[200141][1]} ssr />
                      </div>
                    );
                  }}
                </MythicItem>
              )}
            </div>
          )}
        </div>
        {showAll && (
          <div className="dropdown-multis">
            <div onClick={() => eventFire("Red Kayn", "1")}>
              <Link className="indv-multi-container first" to={multiURL("red")}>
                <span>{"Red"}</span>
                <div className="image-holder">
                  {tabData[100141][1] != 0 && (
                    <div className="rune-image-container">
                      <Rune patch={version} runeId={tabData[100141] && tabData[100141][0]} ssr />
                    </div>
                  )}
                  {tabData[100141] && tabData[100141][1] != 0 && (
                    <MythicItem itemId={tabData[100141] && tabData[100141][1]}>
                      {(isMythic) => {
                        return (
                          <div className={classNames("item-image-container", { "is-mythic": isMythic })}>
                            <Item itemId={tabData[100141] && tabData[100141][1]} ssr />
                          </div>
                        );
                      }}
                    </MythicItem>
                  )}
                </div>
              </Link>
            </div>
            <div onClick={() => eventFire("Blue Kayn", "2")}>
              <Link className="indv-multi-container last" to={multiURL("blue")}>
                <span>{"Blue"}</span>
                <div className="image-holder">
                  {tabData[200141][1] != 0 && (
                    <div className="rune-image-container">
                      <Rune patch={version} runeId={tabData[200141] && tabData[200141][0]} ssr />
                    </div>
                  )}
                  {tabData[200141] && tabData[200141][1] != 0 && (
                    <MythicItem itemId={tabData[200141] && tabData[200141][1]}>
                      {(isMythic) => {
                        return (
                          <div className={classNames("item-image-container", { "is-mythic": isMythic })}>
                            <Item itemId={tabData[200141] && tabData[200141][1]} ssr />
                          </div>
                        );
                      }}
                    </MythicItem>
                  )}
                </div>
              </Link>
            </div>
          </div>
        )}
      </div>
    )
  ) : (
    <>
      <div className={classNames("multi-builds-filter-button", { active: showAll })} ref={multiRef} onClick={handleShowAll}>
        <div className="multi-builds-content">
          <span>{multi}</span>
          <div className="image-holder">
            {multiData[0] != undefined && (
              <div className="rune-image-container">
                <Rune patch={version} runeId={multiData && multiData[0]} ssr />
              </div>
            )}
            {multiData && multiData[1] != 0 && (
              <MythicItem itemId={multiData && multiData[1]}>
                {(isMythic) => {
                  return (
                    <div className={classNames("item-image-container", { "is-mythic": isMythic })}>
                      <Item itemId={multiData && multiData[1]} ssr />
                    </div>
                  );
                }}
              </MythicItem>
            )}
          </div>
        </div>
        {showAll && (
          <div className="dropdown-multis">
            {multiNames.map((build, index) => (
              <div onClick={() => eventFire(build, index)}>
                <Link
                  className={classNames(
                    `indv-multi-container ${
                      multiNames.length === 1 ? "only" : index == 0 ? "first" : index == multiNames.length - 1 ? "last" : ""
                    }`,
                    {
                      active: build === urlParams.multi || (index === 0 && urlParams.multi === undefined),
                    },
                  )}
                  key={index}
                  // to={build === "default" ? recommended() : multiURL(build, multi)}
                  to={multiURL(build === "default" ? undefined : build)}
                >
                  <span>{capitalizeMulti(build)}</span>
                  <div className="image-holder">
                    {tabData[build][0] != undefined && (
                      <div className="rune-image-container">
                        <Rune patch={version} runeId={tabData[build] && tabData[build][0]} ssr />
                      </div>
                    )}
                    {tabData[build] && tabData[build][1] != 0 && (
                      <MythicItem itemId={tabData[build] && tabData[build][1]}>
                        {(isMythic) => {
                          return (
                            <div className={classNames("item-image-container", { "is-mythic": isMythic })}>
                              <Item itemId={tabData[build] && tabData[build][1]} ssr />
                            </div>
                          );
                        }}
                      </MythicItem>
                    )}
                  </div>
                </Link>
              </div>
            ))}
          </div>
        )}
      </div>
      <div className="filter-seperator" />
    </>
  );
};

export default MultiBuildsFilter;
