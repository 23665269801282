import { window } from "global";
import React, { useState, useEffect, useMemo, useRef, useCallback } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Modal from "components/Modal";
import VSChampionFilter from "components/FilterManager/Filters/VSChampionFilter";
import FilterModal from "components/FilterManager/FilterModal";
import SearchFilter from "components/SearchFilter";
import { buildQueryParams, parseQueryString } from "lib/general-helper.js";
import _uniqby from "lodash.uniqby";
import ReactSVG from "react-svg";
import { getRiotAssetsContext } from "@outplayed/riot-assets";
import { useSearchBarPages } from "lib/hooks/searchHooks";
import { ReactComponent as MagnifyingGlass } from "../../svg/magnifying-glass.svg";

const MatchupModal = (props) => {
  const { getChampions, getChampionImg, getNormalizedChampionName } = getRiotAssetsContext();
  const modalWindowRef = useRef();
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const search = useRef();
  const { opp, button, isMobile } = props;
  const [preSelected, setPreSelected] = useState(opp);
  const sortedChampions = useMemo(() => {
    const championData = getChampions() || {};
    const sortedChampions = [];
    for (let [key, championData] of Object.entries(championData)) {
      sortedChampions.push({
        normalizedName: championData.id,
        name: championData.name,
        id: championData.key,
      });
    }
    sortedChampions.sort((a, b) => {
      if (a.name < b.name) return -1;
      else if (a.name > b.name) return 1;
      return 0;
    });
    return sortedChampions;
  }, []);

  useEffect(() => {
    if (preSelected !== opp) {
      setPreSelected(opp);
    }
  }, [opp]);

  const championSearchItem = (option) => {
    return (
      <div className="search-item-container">
        {option.id !== 0 && <img className="champion-image" src={getChampionImg(option.id)} alt={option.name} />}
        <span className="champion-name">{option.name}</span>
      </div>
    );
  };

  const filterChampions = (collection, value) => {
    let listToDisplay = collection;
    if (value.trim() === "") {
      return [];
    }
    let filteredCollection = listToDisplay.filter((element) => {
      const searchTerm = element.searchTerm.toLowerCase();
      const input = value.toLowerCase();

      if (searchTerm.replace(/ /g, "").indexOf(input.replace(/ /g, "")) === 0) {
        return true;
      }

      return false;
    });

    filteredCollection = _uniqby(filteredCollection, (champion) => champion.name);

    const maxCount = 10;
    return filteredCollection.slice(0, maxCount);
  };

  const onClear = (e) => {
    search.current.clearSelectedOption(e);
    setPreSelected(null);
  };

  const onCancel = (e) => {
    setPreSelected(opp);
  };

  const onApply = () => {
    const option = preSelected;

    let simplifiedChampionName = "allChampions";
    if (option && option.id !== 0) {
      simplifiedChampionName = getNormalizedChampionName(option.id);
    }

    let params = parseQueryString(location.search.substring(1));

    if (
      (!params.opp && simplifiedChampionName === "allChampions") ||
      (params.opp && params.opp.toLowerCase() === simplifiedChampionName)
    ) {
      return;
    }

    if (simplifiedChampionName !== "allChampions") {
      params.opp = simplifiedChampionName;
    } else {
      delete params.opp;
    }

    if (window && window.dataLayer) {
      window.dataLayer.push({
        event: "filter-select",
        category: "filter",
        filterType: "matchup",
        filterValue: simplifiedChampionName,
        page: "build",
      });
    }

    history.replace({
      pathname: history.location.pathname,
      search: `?${buildQueryParams(params)}`,
    });
  };

  const onSearchSelect = (option) => {
    setPreSelected(option);
  };

  if (isMobile) {
    return (
      <FilterModal
        button={button}
        title={t("Change Matchup")}
        contentClassNames={"matchup-modal_mobile"}
        onApply={onApply}
        onCancel={onCancel}
      >
        <div className="filter_modal_content__matchup">
          <div className="sub-text">{t("This feature allows you to view builds against any specific champion")}</div>
          <div className="opp-champion-search-bar">
            {preSelected !== null && (
              <div className="champion-selected">
                <img src={getChampionImg(preSelected.id)} alt={preSelected.name} />
              </div>
            )}
            <div className="search-container">
              <div className="search-icon">
                <ReactSVG
                  path="https://static.bigbrain.gg/assets/ugg/icons/_ionicons_svg_ios-search.svg"
                  style={{ fill: "black", height: "20px", width: "20px" }}
                />
              </div>
              {preSelected !== null && (
                <div className="close-icon" onClick={onClear}>
                  <img
                    src="https://static.bigbrain.gg/assets/ugg/icons/_ionicons_svg_md-close.svg"
                    style={{ width: "20px", height: "20px" }}
                    alt="Close"
                  />
                </div>
              )}
              <SearchFilter
                ref={search}
                value={""}
                placeholder="Search Champion"
                filter={filterChampions}
                selectedOption={preSelected}
                customSelected={props.championSelected}
                customItems={championSearchItem}
                options={props.searchChampions}
                showListOnFocus={true}
                onSelect={onSearchSelect}
                autoFocus
              />
            </div>
          </div>
          {!preSelected && (
            <div className="selection-footer">
              <div className="preselected-champion">
                <div>
                  <div>
                    <strong>NO CHAMPION SELECTED</strong>
                  </div>
                  <div>
                    (Will default to <strong>ALL CHAMPIONS</strong>)
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </FilterModal>
    );
  }

  const customCancel = () => {
    modalWindowRef.current && modalWindowRef.current.onCancel();
  };
  const customApply = () => {
    modalWindowRef.current && modalWindowRef.current.onApply();
  };

  return (
    <Modal
      ref={modalWindowRef}
      modalClassNames="matchup-modal select_modal__modal-window__filter"
      dataTip={t("Change Matchup")}
      button={button}
      onApply={onApply}
      onCancel={onCancel}
      bDisableFooter
    >
      <div className="title">MATCHUPS</div>
      <div className="sub-text">{t("This feature allows you to view builds against any specific champion")}</div>
      <div className="opp-champion-search-bar">
        {preSelected !== null && (
          <div className="champion-selected">
            <img src={getChampionImg(preSelected.id)} alt={preSelected.name} />
          </div>
        )}
        <div className="search-container">
          <div className="search-icon">
            <ReactSVG
              path="https://static.bigbrain.gg/assets/ugg/icons/_ionicons_svg_ios-search.svg"
              style={{ fill: "black", height: "20px", width: "20px" }}
            />
          </div>
          {preSelected !== null && (
            <div className="close-icon" onClick={onClear}>
              <img
                src="https://static.bigbrain.gg/assets/ugg/icons/_ionicons_svg_md-close.svg"
                style={{ width: "20px", height: "20px" }}
                alt="Close"
              />
            </div>
          )}
          <SearchFilter
            ref={search}
            value={""}
            placeholder="Search Champion"
            filter={filterChampions}
            selectedOption={preSelected}
            customSelected={props.championSelected}
            customItems={championSearchItem}
            options={props.searchChampions}
            showListOnFocus={true}
            onSelect={onSearchSelect}
            autoFocus
          />
        </div>
      </div>
      <div className="selection-footer">
        <div className="preselected-champion">
          {!preSelected && (
            <div>
              <div>
                <strong>NO CHAMPION SELECTED</strong>
              </div>
              <div>
                (Will default to <strong>ALL CHAMPIONS</strong>)
              </div>
            </div>
          )}
        </div>
        <div className="clear" onClick={customCancel}>
          Cancel
        </div>
        <div className="apply" onClick={customApply}>
          APPLY
        </div>
      </div>
    </Modal>
  );
};

const OppChampionSearch = (props) => {
  const { getChampionJSONByName, getChampionImg, getNormalizedChampionName } = getRiotAssetsContext();
  const history = useHistory();
  const location = useLocation();
  const { data: searchBarPages } = useSearchBarPages();

  const getChampionSearchList = () => {
    if (!searchBarPages) return [];
    let searchList = searchBarPages.find((section) => section.title === "Build");
    return searchList.pages.map((champion) => ({
      id: champion.champId,
      normalizedName: getNormalizedChampionName(champion.champId),
      name: champion.displayName,
      searchTerm: champion.key,
    }));
  };

  const getChampionOption = (opp) => {
    let option;
    const oppChampion = getChampionJSONByName(opp);
    if (!opp || opp === "allChampions" || !oppChampion) {
      option = {
        id: 0,
        name: "Champion...",
        normalizedName: "allChampions",
      };
    } else {
      option = {
        normalizedName: oppChampion.id,
        name: oppChampion.name,
        id: oppChampion.key,
      };
    }

    return option;
  };

  const championSelected = (option, withImg) => {
    return (
      <div className="item-selected">
        {option.id !== 0 && withImg && (
          <div className="champion-image-container">
            <img className="champion-image" src={getChampionImg(option.id)} alt={option.name} />
          </div>
        )}
        <span className="champion-name">{t(option.name)}</span>
      </div>
    );
  };

  const onClearSelection = (e) => {
    e.stopPropagation();

    const params = parseQueryString(location.search.substring(1));
    if (params.opp) {
      delete params.opp;
    }

    const searchWithDefaultOpp = `?${buildQueryParams(params)}`;
    history.push({
      pathname: location.pathname,
      search: searchWithDefaultOpp,
    });
  };

  const { t } = useTranslation();
  const { filters, isMobile } = props;
  const currentOppChampion = getChampionOption(filters.opp);
  const searchChampions = useMemo(() => getChampionSearchList(), [searchBarPages]);

  const button = (
    <div className={"filter-select filter-select_opp"}>
      <div className="opp-selected">
        <span className="vs-placeholder">{t(`vs.`)}</span>
        {championSelected(currentOppChampion, true)}
        <MagnifyingGlass className="magnifying-glass" />
        {filters.opp !== "allChampions" && (
          <div className="clear-selected-option" onClick={onClearSelection}>
            <img src="https://static.bigbrain.gg/assets/ugg/icons/_ionicons_svg_md-close.svg" alt="close" />
          </div>
        )}
      </div>
    </div>
  );

  return <VSChampionFilter />;
};

export default OppChampionSearch;
