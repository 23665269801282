import { window } from "global";
import React, { useState, useEffect, useMemo } from "react";
import { useLocation, useHistory, useParams, useRouteMatch } from "react-router-dom";
import { useUGGPatches } from "lib/hooks/patchHooks";
import { getValidParamKeys, useValidateQueryParams } from "query_parameters/paramsHelper";
import { validParams } from "query_parameters/valid_parameters";
import * as generalHelper from "lib/general-helper.js";
import { useQueryString } from "hooks/general-hooks";
import MediaQuery from "components/MediaQuery";
import QueueType from "./Filters/QueueType";
import Rank from "./Filters/Rank";
import Region from "./Filters/Region";
import Role from "./Filters/Role";
import RoleSplit from "./Filters/RoleSplit";
import TierListRole from "./Filters/TierListRole";
import TierListRoleSplit from "./Filters/TierListRoleSplit";
import NexusBlitzRoleSplit from "./Filters/NexusBlitzRoleSplit";
import DuoRole from "./Filters/DuoRole";
import Patch from "./Filters/Patch";
import More from "./Filters/More";
import MultiBuildsFilter from "./Filters/MultiBuildsFilter";
import ShowAll from "./Filters/ShowAll";
import OppMatchup from "./Filters/OppMatchup";
import VictoryOnly from "./Filters/VictoryOnly";
import { ReactComponent as FilterIcon } from "svg/filters-icon.svg";
import { ReactComponent as FilterIconNew } from "svg/filter-icon-new.svg";
import { getSpecialQueueTypeBuildOrTierList } from "lib/specialGameMode";

import { match, compile } from "path-to-regexp";
import ChampionOverviewRoleSplit from "./Filters/ChampionOverviewRoleSplit";
import { useGlobal } from "reactn";
import MoreLessButton from "./Filters/More";

const FilterManager = (props) => {
  const [showMore, setShowMore] = useState(props.collapsable === false);
  const [showAll, setShowAll] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const queryParams = useQueryString();
  const [language] = useGlobal("language");
  const urlParams = useParams();
  const routerMatch = useRouteMatch();

  const handleShowAll = () => {
    setShowAll(!showAll);
  };

  const {
    className,
    disable,
    enable,
    page,
    isFetching,
    pageData,
    isMobile,
    collapsable,
    overrideDefaultFilters,
    sideFilterText,
  } = props;

  const validateQueryParams = useValidateQueryParams();
  const { data: uggPatches } = useUGGPatches();

  const editQueueTypeLocation = (isQueueType, buildPageName, queuePathname, tierListName) => {
    if (page === "build" || page === buildPageName) {
      const pathNameList = location.pathname.split("/");
      if (!isQueueType && pathNameList.length > 1 && pathNameList[3] === queuePathname) {
        const champName = pathNameList.pop().split("-")[0];
        return `/lol/champions/${champName}/build`;
      } // If we're on the build page and have a multibuild selected
      else if (isQueueType && urlParams.multi && queuePathname === "aram") {
        const toPath = compile(routerMatch.path);
        return toPath({ ...urlParams, multi: undefined });
      } else if (isQueueType && (pathNameList.length < 1 || pathNameList[3] !== queuePathname)) {
        // pathNameList.pop();
        // const champName = pathNameList.pop();
        const position = pathNameList.indexOf("champions");
        const champName = pathNameList[position + 1];
        // pathNameList.push(queuePathname);
        // pathNameList.push(`${champName}-${queuePathname}`);
        // return pathNameList.join("/");
        return `/lol/champions/${queuePathname}/${champName}-${queuePathname}`;
      }
    } else if (page === "tier-list" || page === tierListName) {
      const pathNameList = location.pathname.split("/");
      if (!isQueueType && pathNameList.includes(tierListName)) {
        return "tier-list";
      } else if (isQueueType && !pathNameList.includes(tierListName)) {
        return tierListName;
      }
    }
    return location.pathname;
  };

  const editAramLocation = (aramTrue) => {
    return editQueueTypeLocation(aramTrue, "overview_aram", "aram", "aram-tier-list");
  };

  const editUrfLocation = (urfTrue) => {
    return editQueueTypeLocation(urfTrue, "overview_urf", "urf", "urf-tier-list");
  };

  const editArurfLocation = (arurfTrue) => {
    return editQueueTypeLocation(arurfTrue, "overview_arurf", "arurf", "arurf-tier-list");
  };

  const editOneForAllLocation = (oneForAllTrue) => {
    return editQueueTypeLocation(oneForAllTrue, "overview_one_for_all", "one-for-all", "one-for-all-tier-list");
  };

  const editNexusBlitzLocation = (nexusBlitzTrue) => {
    return editQueueTypeLocation(nexusBlitzTrue, "overview_nexus_blitz", "nexus-blitz", "nexus-blitz-tier-list");
  };

  const editArenaLocation = (nexusBlitzTrue) => {
    return editQueueTypeLocation(nexusBlitzTrue, "overview_arena", "arena", "arena-tier-list");
  };

  const updateQueryParams = (newFilter, pathName) => {
    // delete filters that we don't need
    let removeKeys = disable || [];
    let curFilter = generalHelper.removeKeys(newFilter, removeKeys);

    const newLocation = {
      ...location,
      pathname: pathName ? pathName : location.pathname,
    };

    if (pathName !== location.pathname) {
      generalHelper.updateQueryParams(history, newLocation.pathname, curFilter);
    } else {
      generalHelper.updateQueryParamsIfChanged(history, newLocation, curFilter);
    }
  };

  const onFilterChange = (filterType, filterValue) => {
    let newFilter = {};

    window.dataLayer.push({ event: "uggNewPage" });

    if (window && window.egAps && typeof window.egAps.reinstate === "function") {
      window.setTimeout(function () {
        egAps.reinstate();
      }, 1000);
    }

    if (window && window.lngtd) {
      // refresh ads
      console.log("refresh lngtd");
      window.lngtd.resetAndRunAuction();
      if (window.dataLayer) {
        window.dataLayer.push({
          event: "refresh-longitude-ads",
          category: "refresh-longitude-ads",
          value: "0.45",
        });
      }
    }

    if (filterType && filterValue) {
      newFilter[filterType] = filterValue.value;

      if (window.dataLayer) {
        window.dataLayer.push({
          event: "filter-select",
          category: "filter",
          filterType: filterType,
          filterValue: filterValue.value,
          page: page,
        });
      }
    }

    if (filterType === "queueType" && filterValue !== "normal_aram" && filterValue != "nexus_blitz") {
      delete newFilter.role;
    }

    newFilter = { ...queryParams, ...newFilter };
    newFilter = validateQueryParams({ queryParams: newFilter, page });
    ///////////
    // ARAM
    // Hacky. If we nav to the aram url with the direct url, it won't know that the default queueType is aram so when we change another filter; it'll revert us to that queueType.
    // So if we are on the aram url, just force the queueType too aram unless they are changing it.
    const specialQueueType = getSpecialQueueTypeBuildOrTierList(page, location);
    if (specialQueueType && filterType !== "queueType") {
      newFilter.queueType = specialQueueType;
    }

    const isAram = (filterType === "queueType" && filterValue.value === "normal_aram") || newFilter.queueType === "normal_aram";
    const isUrf = (filterType === "queueType" && filterValue.value === "urf") || newFilter.queueType === "urf";
    const isArurf = (filterType === "queueType" && filterValue.value === "arurf") || newFilter.queueType === "arurf";
    const isOneForAll =
      (filterType === "queueType" && filterValue.value === "one_for_all") || newFilter.queueType === "one_for_all";
    const isNexusBlitz =
      (filterType === "queueType" && filterValue.value === "nexus_blitz") || newFilter.queueType === "nexus_blitz";
    const isArena = (filterType === "queueType" && filterValue.value === "arena") || newFilter.queueType === "arena";

    if (
      (isAram && (page === "build" || location.pathname.includes("/build") || location.pathname.includes("tier-list"))) ||
      (isUrf && (page === "build" || location.pathname.includes("/build") || location.pathname.includes("tier-list"))) ||
      (isArurf && (page === "build" || location.pathname.includes("/build") || location.pathname.includes("tier-list"))) ||
      (isOneForAll && (page === "build" || location.pathname.includes("/build") || location.pathname.includes("tier-list"))) ||
      (isNexusBlitz && (page === "build" || location.pathname.includes("/build") || location.pathname.includes("tier-list")))
    ) {
      delete newFilter.queueType;
    }

    let newUrl = editAramLocation(isAram);
    if (isUrf) {
      newUrl = editUrfLocation(isUrf);
    }
    if (isArurf) {
      newUrl = editArurfLocation(isArurf);
    }
    if (isOneForAll) {
      newUrl = editOneForAllLocation(isOneForAll);
    }
    if (isNexusBlitz) {
      newUrl = editNexusBlitzLocation(isNexusBlitz);
    }
    if (isArena) {
      newUrl = editArenaLocation(isArena);
    }

    if (page === "build" && filterType === "role") {
      const routeRole = filterValue.value === "middle" ? "mid" : filterValue.value;
      if (language.toLowerCase() === "en_us") {
        newUrl = `/lol/champions/${props.normalizedChampionName}/build/${routeRole}`;
      } else {
        newUrl = `/lol/${language.toLowerCase()}/champions/${props.normalizedChampionName}/build/${routeRole}`;
      }

      delete newFilter.role;
    }
    updateQueryParams(newFilter, newUrl);
  };

  const onResetFilters = () => {
    history.replace({ pathname: editUrfLocation(false) });
    history.replace({ pathname: editArurfLocation(false) });
    history.replace({ pathname: editAramLocation(false) });
    history.replace({ pathname: editOneForAllLocation(false) });
  };

  // Returns classname to check if default option is selected
  const isDefault = (filterKey, currentFilters, defaultFilters) => {
    return (currentFilters && currentFilters[filterKey]) === (defaultFilters && defaultFilters[filterKey])
      ? ""
      : "filter_not-default";
  };

  let validPageParams = validParams[page] || {};
  let paramDefaultOverrides = overrideDefaultFilters ? overrideDefaultFilters : {};
  let defaultParams = validPageParams.default ? { ...validPageParams.default } : {};

  const aramAdjustedQueryParam = { ...queryParams };
  if (location.pathname.includes("/build/aram/")) aramAdjustedQueryParam.queueType = "normal_aram";
  else if (location.pathname.includes("/build/arurf/")) aramAdjustedQueryParam.queueType = "arurf";
  else if (location.pathname.includes("/build/one-for-all/")) aramAdjustedQueryParam.queueType = "one_for_all";

  const validatedQueryParams = validateQueryParams({ queryParams, page, keepDefaultParams: true });
  const filters = {
    ...defaultParams,
    ...validatedQueryParams,
    ...paramDefaultOverrides,
    ...aramAdjustedQueryParam,
  };

  const validParamKeys = getValidParamKeys(page);
  const options = { ...validPageParams };
  const filterProps = {
    ...props,
    isMobile,
    defaultParams,
    filters,
    onFilterChange,
    history,
    location,
  };

  const patchFilterProps = useMemo(() => {
    const formatPatchOptions = (list) =>
      list.map((el) => ({
        value: el,
        label: el.replace("_", "."),
      }));

    const { patch } = options || {};
    let patchOptions = [];
    let isDefaultPatch = false;
    if (patch) {
      if (patch.options === "latest") {
        patchOptions = formatPatchOptions(uggPatches.slice(0, patch.max));
        isDefaultPatch = filters.patch === uggPatches[0];
      } else if (Array.isArray(patch.options)) {
        patchOptions = formatPatchOptions(patch.options);
        isDefaultPatch = filters.patch === defaultParams.patch;
      }
    }

    return { patchOptions, isDefaultPatch: isDefaultPatch ? "" : "filter_not-default" };
  }, [JSON.stringify(options.patch), JSON.stringify(filters)]);

  const isTestSEOChampionsBuild = ["/lol/:language?/champions/:champion/build/:role?"].some((path) => {
    const foundMatch = match(path)(location.pathname);
    if (foundMatch) {
      const {
        params: { champion },
      } = foundMatch;
      return true;
    }
    return false;
  });

  return (
    <div className={`_filter-manager ${className}`}>
      <div className="filter-width-wrapper">
        <div className="filter-manager_container">
          <div className="filter-manager_label">
            <FilterIconNew />
            <MediaQuery min="TABLET" max="DESKTOP_LARGE">
              <span>Filters</span>
            </MediaQuery>
          </div>
          {(page == "build" || page == "overview_aram") && <MultiBuildsFilter role={filterProps.role} />}
          {validParamKeys.role && page !== "tier-list" && page !== "nexus-blitz-tier-list" && page !== "overview_nexus_blitz" && (
            <React.Fragment>
              <MediaQuery min="MOBILE_SMALL" max="DESKTOP_SMALL">
                <Role {...filterProps} options={options.role} isDefault={isDefault("role", filters, defaultParams)} />
              </MediaQuery>
              <MediaQuery min="DESKTOP_MEDIUM" max="DESKTOP_LARGE">
                {isTestSEOChampionsBuild ? <ChampionOverviewRoleSplit {...filterProps} /> : <RoleSplit {...filterProps} />}
              </MediaQuery>
            </React.Fragment>
          )}
          {page === "tier-list" && (
            <React.Fragment>
              <MediaQuery min="MOBILE_SMALL" max="TABLET">
                <TierListRole {...filterProps} />
              </MediaQuery>
              <MediaQuery min="DESKTOP_SMALL" max="DESKTOP_LARGE">
                <TierListRoleSplit {...filterProps} />
              </MediaQuery>
            </React.Fragment>
          )}
          {(page === "nexus-blitz-tier-list" || page === "overview_nexus_blitz") && (
            <React.Fragment>
              <MediaQuery min="MOBILE_SMALL" max="DESKTOP_SMALL">
                <Role {...filterProps} options={options.role} isDefault={isDefault("role", filters, defaultParams)} />
              </MediaQuery>
              <MediaQuery min="DESKTOP_MEDIUM" max="DESKTOP_LARGE">
                <NexusBlitzRoleSplit {...filterProps} />
              </MediaQuery>
            </React.Fragment>
          )}
          {validParamKeys.duo && (
            <DuoRole {...filterProps} options={options.duo} isDefault={isDefault("duo", filters, defaultParams)} />
          )}
          {validParamKeys.rank && (
            <Rank {...filterProps} options={options.rank} isDefault={isDefault("rank", filters, defaultParams)} />
          )}
          {validParamKeys.opp && <OppMatchup {...filterProps} />}
          {(true || collapsable === false) && (showAll || page !== "build") && (
            <React.Fragment>
              {validParamKeys.queueType && (
                <QueueType //
                  {...filterProps}
                  options={options.queueType}
                  isDefault={isDefault("queueType", filters, defaultParams)}
                />
              )}
              {/*
                validParamKeys.playerType &&
                  <PlayerType {...filterProps} options={options.playerType} isDefault={isDefault("pro", filters, defaultParams)} />
              */}
              {validParamKeys.patch && (
                <Patch {...filterProps} options={patchFilterProps.patchOptions} isDefault={patchFilterProps.isDefaultPatch} />
              )}
              {validParamKeys.region && (
                <Region {...filterProps} options={options.region} isDefault={isDefault("region", filters, defaultParams)} />
              )}
              {/*
                validParamKeys.league &&
                  <LeagueSeries {...filterProps} options={options.league} />
              */}
              {/*
                validParamKeys.league2 &&
                  <CompareLeagueSeries {...filterProps} options={options.league2} />
              */}
              {validParamKeys.allChampions && <ShowAll {...filterProps} />}
              {validParamKeys.isVictoryOnly && <VictoryOnly {...filterProps} />}
            </React.Fragment>
          )}
          {page === "build" && (
            <div onClick={handleShowAll}>
              <MoreLessButton label={showAll} />
            </div>
          )}
          {/*
            collapsable !== false &&
            <div className="filter-collapse" onClick={() => setShowMore(!showMore)}>
              {
                isMobile
                  ? <div>{"..."}</div>
                  : <div>{showMore ? "Less..." : "More..."}</div>
              }
            </div>
          */}
          {/*
            location.search && location.search.substring(1) !== "" && (
              <div className="reset-filters" onClick={onResetFilters}>
                Reset
              </div>
            )
          */}
          {sideFilterText}
        </div>
      </div>
    </div>
  );
};

const FilterManagerResponsive = (props) => {
  return (
    <React.Fragment>
      <MediaQuery min="MOBILE_SMALL" max="MOBILE_LARGE">
        <FilterManager {...props} isMobile />
      </MediaQuery>
      <MediaQuery min="TABLET" max="DESKTOP_LARGE">
        <FilterManager {...props} />
      </MediaQuery>
    </React.Fragment>
  );
};

export default FilterManagerResponsive;

FilterManager.defaultProps = {
  enable: [], // Array of keys to enable. playerType is disabled by default
  disable: [], // Array of keys to disable/hide specific filters
  allRoles: false, // Allow all roles to be selected, otherwise define own filtering of roles
};
