import React from "react";

class SearchFilter extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      value: "",
      inputChanged: true,
      listOpen: false,
      orientation: "bottom",
      defaultSelectedOption: props.defaultSelectedOption,
      selectedOption: props.selectedOption,
      list: [],
      listItemIndex: -1,
    };

    this.myRef = React.createRef();
    this.onFocusHandler = this.onFocusHandler.bind(this);
    this.onBlurHandler = this.onBlurHandler.bind(this);
    this.onKeyDownHandler = this.onKeyDownHandler.bind(this);
    this.onChangeHandler = this.onChangeHandler.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleOutsideClick = this.handleOutsideClick.bind(this);
    this.clearSelectedOption = this.clearSelectedOption.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.listOpen && prevState.list.length !== this.state.list.length) {
      const { top, bottom } = this.input.getBoundingClientRect();
      const searchListHeight = this.searchList.getBoundingClientRect().height;

      const overflowTop = searchListHeight + 10 > top - 85;
      const overflowBottom = bottom + searchListHeight + 10 > window.innerHeight;

      if (overflowBottom && overflowTop) {
        this.setState({ orientation: "bottom" });
      } else if (overflowBottom) {
        this.setState({ orientation: "top" });
      } else {
        this.setState({ orientation: "bottom" });
      }
    }

    if (prevProps.value !== this.props.value) {
      this.setState({ inputChanged: false });
    }
  }

  handleClick(e) {
    if (!this.state.listOpen) {
      this.onFocusHandler(e);
      document.addEventListener("click", this.handleOutsideClick, false);
    } else {
      this.onBlurHandler(e);
      document.removeEventListener("click", this.handleOutsideClick, false);
    }
  }

  handleOutsideClick(e) {
    if (this.myRef && this.myRef.current && this.myRef.current.contains(e.target)) {
      return this.onBlurHandler(e);
    }
    this.handleClick(e);
  }

  clearSelectedOption(e) {
    e.stopPropagation();
    this.props.onClear && this.props.onClear();
    this.setState({ selectedOption: null });
  }

  buildList(input, listItemIndex) {
    let filteredList = this.props.filter(this.props.options, input, this.props.suggestions);
    if (this.props.showListOnFocus && input === "") {
      filteredList = this.props.filter(this.props.options, "", this.props.suggestions);
    }
    const options = filteredList.map((item, index) => {
      const hovered = index === listItemIndex ? "hovered" : "";

      return (
        <div key={index} className={hovered} onClick={this.onSelectHandler.bind(this, item)}>
          {this.props.customItems(item)}
        </div>
      );
    });

    return options;
  }

  onKeyDownHandler(e) {
    let { list, listItemIndex } = this.state;
    let nextIndex = null;
    let options = [];

    if (list.length === 0) return;

    if (e.keyCode === 38) {
      // Up
      e.preventDefault(); // Prevent cursor from moving to start
      nextIndex = listItemIndex === -1 || listItemIndex === 0 ? list.length - 1 : listItemIndex - 1;
      options = this.buildList(this.state.value, nextIndex);
      this.setState({ list: options, listItemIndex: nextIndex });
    } else if (e.keyCode === 40) {
      // Down
      e.preventDefault(); // Prevent cursor from moving to end
      nextIndex = listItemIndex === list.length - 1 ? 0 : listItemIndex + 1;
      options = this.buildList(this.state.value, nextIndex);
      this.setState({ list: options, listItemIndex: nextIndex });
    } else if (e.keyCode === 13) {
      // Enter
      const filteredList = this.props.filter(this.props.options, this.state.value, this.props.suggestions);
      if (this.state.listItemIndex !== -1) {
        this.input.blur();
        this.onSelectHandler(filteredList[this.state.listItemIndex]);
        document.removeEventListener("click", this.handleOutsideClick, false);
      }

      if (filteredList.length === 1) {
        this.input.blur();
        this.onSelectHandler(filteredList[0]);
        document.removeEventListener("click", this.handleOutsideClick, false);
      }
    }
  }

  onChangeHandler(e) {
    e.persist();

    let value;
    let options;
    if (this.props.showListOnFocus) {
      if (e.target.value !== "") {
        value = e.target.value;
      } else {
        value = "";
      }
      options = this.buildList(value, this.state.listItemIndex);
      this.setState({ listOpen: true, list: options, inputChanged: true, value: e.target.value });
    } else {
      if (e.target.value !== "") {
        value = e.target.value;
        options = this.buildList(value, this.state.listItemIndex);
      } else {
        value = "";
        options = [];
      }
      this.setState({ listOpen: true, list: options, inputChanged: true, value: e.target.value });
    }
  }

  onSelectHandler(item) {
    this.onBlurHandler();
    this.setState({ selectedOption: item });
    this.props.onSelect(item);
    this.setState({ value: "", inputChanged: false });
  }

  onFocusHandler(e) {
    let options = [];

    let value = this.state.value;
    if (this.props.showListOnFocus) {
      if (this.state.value !== "") {
        value = this.state.value;
      } else {
        value = "";
      }
      options = this.buildList(value, -1);
    } else {
      if (this.state.value === "") {
        value = "";
        options = [];
      } else {
        value = this.state.value;
        options = this.buildList(value, -1);
      }
    }

    if (!this.state.listOpen) {
      this.setState({ listOpen: true, list: options, value: value });
    }
  }

  onBlurHandler(e) {
    const options = this.buildList(this.state.value, -1);
    this.setState({ listOpen: false, list: options, listItemIndex: -1 });
  }

  blurDelay(e) {
    setTimeout(() => {
      // Hacky
      if (this.input) {
        this.input.blur();
      }
    }, 200);
  }

  displayField() {
    const { list, listOpen, selectedOption, defaultSelectedOption, inputChanged } = this.state;

    if (!listOpen && this.props.customSelected && !selectedOption && defaultSelectedOption) {
      return this.props.customSelected(defaultSelectedOption);
    } else if (!listOpen && this.props.customSelected && selectedOption) {
      return this.props.customSelected(selectedOption);
    } else {
      const newId = this.props.newId ? this.props.newId : null;
      return (
        <input
          id={newId}
          className={`search-input ${listOpen && list.length > 0 ? "list_open" : "list_closed"}`}
          ref={(input) => (this.input = input)}
          placeholder={this.props.placeholder || ""}
          value={inputChanged ? this.state.value : this.props.value || ""}
          onKeyDown={this.onKeyDownHandler}
          onChange={this.onChangeHandler}
          autoFocus={this.props.autoFocus || this.props.customSelected ? true : false}
        />
      );
    }
  }

  render() {
    const { listOpen, list, selectedOption, orientation } = this.state;
    const { onClear } = this.props;

    const listOpenClassName = listOpen && list.length > 0 ? "show" : "hide";

    return (
      <div className="search-filter-wrapper" ref={this.myRef} onClick={this.handleClick}>
        {this.displayField()}
        {onClear && selectedOption && !listOpen && (
          <div className="clear-selected-option" onClick={this.clearSelectedOption}>
            <img src={"https://static.bigbrain.gg/assets/ugg/icons/_ionicons_svg_md-close.svg"} />
          </div>
        )}
        <div
          ref={(searchList) => (this.searchList = searchList)}
          className={"search-list " + listOpenClassName}
          onMouseDown={this.blurDelay.bind(this)}
          style={
            orientation === "top"
              ? { bottom: "50px", top: "inherit", overflow: "auto" }
              : { top: "50px", bottom: "inherit", overflow: "auto" }
          }
        >
          {list}
        </div>
      </div>
    );
  }
}

export default SearchFilter;
